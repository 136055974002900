.hero {
  color: var(--white);
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 1rem;
  gap: 9rem;
  h2:first-of-type {
    font-size: 3.6rem;
    color: var(--red);
    letter-spacing: 0.1rem;
    transition: all 0.3s ease;
  }
  h2:nth-of-type(2) {
    font-size: 2.2rem;
    color: var(--grey);
    letter-spacing: 0.4rem;
    margin-top: 2rem;
    transition: all 0.3s ease;
  }
  .intro {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 15rem;
    animation: logo-spin infinite 20s linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ======================== TYPE TEXT

.type-box {
  height: 8.5rem;
  width: 41rem;
}

.typed {
  h1 {
    font-family: 'Calibre-SB', sans-serif;
    font-size: 9.6rem;
    transition: all 0.3s ease;
  }
  height: 100%;
  overflow: hidden;
  border-right: 0.6em solid var(--grey);
  white-space: nowrap;
  animation: typing 6s steps(18, end) forwards infinite, blink 1.2s infinite;
}

@keyframes typing {
  0% {
    width: 0;
  }
  20% {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 0%;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: var(--grey);
  }
}

// ======================== SCROLL

$base: 0.6rem;

.hero {
  .chevron-container {
    bottom: 10rem;
    right: 10rem;
    position: absolute;

    .chevron {
      position: absolute;
      width: $base * 3.5;
      height: $base * 0.8;
      opacity: 0;
      transform: scale(0.3);
      animation: move-chevron 3s ease-out infinite;
    }

    .chevron:first-child {
      animation: move-chevron 3s ease-out 1s infinite;
    }

    .chevron:nth-child(2) {
      animation: move-chevron 3s ease-out 2s infinite;
    }

    .chevron:before,
    .chevron:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      background: var(--red);
    }

    .chevron:before {
      left: 0;
      transform: skewY(30deg);
    }

    .chevron:after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg);
    }

    @keyframes move-chevron {
      25% {
        opacity: 1;
      }
      33.3% {
        opacity: 1;
        transform: translateY($base * 3.8);
      }
      66.6% {
        opacity: 1;
        transform: translateY($base * 5.2);
      }
      100% {
        opacity: 0;
        transform: translateY($base * 8) scale(0.5);
      }
    }
  }
}

// ======================== MEDIA QUERY

@media screen and (max-width: 500px) {
  .hero {
    padding: 10rem 5rem;
    .type-box {
      width: 30rem;
      height: 6.5rem;
      h1 {
        font-size: 7rem;
      }
    }
    h2:first-of-type {
      font-size: 3rem;
    }
    h2:nth-of-type(2) {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 770px) {
  .hero .chevron-container {
    right: 50%;
  }
}

@media screen and (max-device-width: 500px) {
  .hero {
    padding-top: 5rem;
    text-align: center;
    .chevron-container {
      bottom: 15rem;
    }
    .type-box,
    h1,
    h2:first-of-type,
    h2:nth-of-type(2) {
      width: 32.5rem;
      letter-spacing: 2px;
    }
  }
}
