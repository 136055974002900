// ================== FONTS

@font-face {
  font-family: 'Calibre-SB';
  src: url('/public/fonts/CalibreSemibold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre';
  src: url('/public/fonts/CalibreRegular.otf');
  font-display: swap;
}

// ================== GENERAL

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Calibre', 'Calibre-SB', sans-serif;
  // get rid of blue focus on mobile when clicking
  -webkit-tap-highlight-color: transparent;
}

html {
  height: 100vh;
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--darkgrey);
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, sans-serif;
}

.content {
  position: relative;
}

// ================== LINK & SCROLLBAR

a {
  text-decoration: none;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: var(--darkgrey);
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

::-webkit-scrollbar-thumb:horizontal {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: var(--lightgrey);
}

// ================== FADE IN / OUT

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out,
    visibility 0.5s ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
