.design-thumbnails ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.design-thumbnails li {
  width: 33.33%;
  position: relative;
  overflow: hidden;
}

.design-thumbnails li:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.design-thumbnails .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.design-thumbnails .thumbnail .thumbnail-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.design-thumbnails .thumbnail-hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  opacity: 0;
  text-align: center;
  color: var(--white);
  background: rgba(39, 39, 39, 0.5);
  width: 100%;
  height: 100%;
  padding: 2rem;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.design-thumbnails .thumbnail-hover p {
  font-family: 'Calibre-SB', sans-serif;
  word-wrap: break-word;
  font-size: 3.6rem;
  line-height: 4rem;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.design-thumbnails .thumbnail:hover + .thumbnail-hover,
.design-thumbnails .thumbnail-hover:hover {
  opacity: 1;
  cursor: pointer;
}

.design-thumbnails .thumbnail:hover + .thumbnail-hover p,
.design-thumbnails .thumbnail-hover:hover p {
  opacity: 0.8;
}

@media screen and (max-width: 900px) {
  .design-thumbnails ul li {
    width: 50%;
  }
}
