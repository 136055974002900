#illustration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-left: 10rem;

  .illustration-desc {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    h1 {
      font-family: 'Calibre-SB', sans-serif;
      font-size: 6.4rem;
    }
    p {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  .illustration-title-desc {
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    gap: 1rem;
    a {
      text-decoration: none;
    }
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 970px) {
  #illustration {
    padding: 0;
    .illustration-title-desc {
      padding: 10rem;
    }
    .illustration-desc {
      display: block;
      gap: 0;
    }
  }
}

@media screen and (max-width: 770px) {
  #illustration .illustration-title-desc {
    padding: 10rem 5rem;
  }
}
