#design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--white);
  padding: 10rem;
  min-height: 100vh;
}

.designs-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

.designs-desc h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 6.4rem;
}

.designs-desc p {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

.designs-title-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  gap: 1rem;
}

.designs-title-desc a {
  text-decoration: none;
}

.adobe-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  gap: 5rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.adobe-icons .icon-row-1,
.adobe-icons .icon-row-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.adobe-icons img {
  max-width: 11rem;
  height: auto;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1004px) {
  .designs-desc {
    gap: 5rem;
  }
  .adobe-icons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 3rem;
  }
  .adobe-icons .icon-row-1,
  .adobe-icons .icon-row-2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 9rem;
  }
  .adobe-icons img {
    max-width: 10rem;
  }
}

@media screen and (max-width: 970px) {
  #design {
    padding: 0;
    min-height: 0;
  }
  #design .designs-desc {
    display: block;
    gap: 0;
  }
  #design .designs-desc .designs-title-desc {
    padding: 10rem;
  }
  #design .designs-desc .adobe-icons {
    padding: 0 5rem 10rem 5rem;
  }
  #design .designs-desc .adobe-icons .icon-row-1,
  #design .designs-desc .adobe-icons .icon-row-2 {
    gap: 6rem;
  }
}

@media screen and (max-width: 770px) {
  #design .designs-desc .designs-title-desc {
    padding: 10rem 5rem 5rem 5rem;
  }
}

@media screen and (max-width: 500px) {
  .icon-row-1 img,
  .icon-row-2 img {
    max-width: 6rem;
    border-radius: 20px;
  }
}

.ind {
  color: #ff3066;
}

.ps {
  color: #30a8ff;
}

.ai {
  color: #ff9a00;
}

.xd {
  color: #ff61f7;
}

.pp {
  color: #9999ff;
}

.ae {
  color: #9999ff;
}
