.projects-scroller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  max-height: 80vh;
  flex: 1 1 50%;
  transition: all 0.5s ease;
}

.projects-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4rem;
  width: 100%;
  overflow: auto;
  height: 100%;
  padding: 1rem 2rem 0 0.5rem;
  transition: all 0.5s ease;

  &::-webkit-scrollbar {
    background-color: var(--lightgrey);
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--slategrey);
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 970px) {
  .projects-scroller {
    margin-top: 5rem;
    max-height: 85vh;
    .projects-container {
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 0 2rem 0;
    }
  }
}
