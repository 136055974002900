#illustration {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 10rem;
}

#illustration .illustration-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5rem;
}

#illustration .illustration-desc h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 6.4rem;
}

#illustration .illustration-desc p {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

#illustration .illustration-title-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  gap: 1rem;
}

#illustration .illustration-title-desc a {
  text-decoration: none;
}

@media screen and (max-width: 970px) {
  #illustration {
    padding: 0;
  }
  #illustration .illustration-title-desc {
    padding: 10rem;
  }
  #illustration .illustration-desc {
    display: block;
    gap: 0;
  }
}

@media screen and (max-width: 770px) {
  #illustration .illustration-title-desc {
    padding: 10rem 5rem;
  }
}
