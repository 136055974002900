.projects-scroller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
  max-height: 80vh;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.projects-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  gap: 4rem;
  width: 100%;
  overflow: auto;
  height: 100%;
  padding: 1rem 2rem 0 0.5rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.projects-container::-webkit-scrollbar {
  background-color: var(--lightgrey);
}

.projects-container::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: var(--slategrey);
}

@media screen and (max-width: 970px) {
  .projects-scroller {
    margin-top: 5rem;
    max-height: 85vh;
  }
  .projects-scroller .projects-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 2rem 0;
  }
}
