.project-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  gap: 5rem;
  min-width: 99%;
}

.project-name .project-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
}

.project-name .project-banner img {
  width: 100%;
  -webkit-box-shadow: 0px 0px 10px rgba(71, 71, 71, 0.1);
          box-shadow: 0px 0px 10px rgba(71, 71, 71, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-name .project-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.project-name .project-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.project-name .project-header a {
  color: var(--black);
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.project-name .project-header p {
  font-size: 3.2rem;
  color: var(--black);
}

.project-name .project-header p :hover {
  color: var(--red);
}

.project-name .project-header p:nth-of-type(2) {
  font-size: 1.6rem;
}

.project-name .project-header .project-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.project-name .project-header svg {
  margin: 0;
}

.project-name .project-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.project-name .project-details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.project-name .project-details .tech-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 1.5rem 0 1rem 0;
}

.project-name .project-details .tech-stack li {
  color: var(--grey);
  list-style: none;
  font-size: 1.6rem;
  margin-right: 1rem;
}

.project-name .project-desc {
  margin-top: 1rem;
  min-height: 11.3rem;
}

.project-name .project-desc p {
  font-size: 1.6rem;
  line-height: 2rem;
}

@media screen and (max-width: 970px) {
  .project-name {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 1rem 1rem 0 1rem;
  }
}
