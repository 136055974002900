footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  font-size: 1.2rem;
  bottom: 0;
  left: 3.5rem;
  text-align: center;
  margin: 3.5rem 0;

  hr {
    width: 1px;
    height: 9rem;
    border: none;
    background-color: var(--red);
  }
  a {
    padding: 0.5rem;
  }
}

svg {
  width: 20px;
  height: 20px;
  fill: transparent;
}

// ================== MEDIA QUERY

@media screen and (max-width: 770px) {
  footer {
    opacity: 0;
  }
}
