@charset "UTF-8";
#projects {
  padding: 10rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  background-color: var(--offwhite);
  color: var(--black);
}

.projects-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  gap: 5rem;
}

.projects-desc h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 6.4rem;
}

.projects-desc p {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

.projects-title-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  gap: 1rem;
}

.projects-title-desc span {
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.projects-title-desc .gh {
  color: var(--green);
}

.projects-title-desc .fsa {
  color: var(--red);
}

.projects-title-desc span:hover {
  text-underline-offset: 0.4rem;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-color: inherit;
          text-decoration-color: inherit;
}

.projects-title-desc .skills {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(14rem, 20rem))[2];
      grid-template-columns: repeat(2, minmax(14rem, 20rem));
  margin-top: 2rem;
  list-style: none;
}

.projects-title-desc .skills li {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 2rem;
  font-size: 1.6rem;
}

.projects-title-desc .skills li::before {
  content: '»';
  position: absolute;
  font-size: 1.6rem;
  line-height: 1.2rem;
  left: 0px;
  top: 3px;
  color: var(--red);
}

@media screen and (max-width: 970px) {
  #projects {
    height: auto;
  }
  #projects .projects-desc {
    display: block;
  }
  #projects .projects-desc .skills {
    margin: 2rem auto 0;
  }
}

@media screen and (max-width: 770px) {
  #projects {
    height: auto;
    padding: 10rem 5rem;
  }
}
