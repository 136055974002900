.illustrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 1 1 60%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
