#contact {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10rem;
  text-align: center;
  min-height: 100vh;
  background-color: var(--offwhite);
  color: var(--black);
}

#contact .about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 10%;
}

#contact .about img {
  width: 15rem;
  border-radius: 100%;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

#contact span {
  color: var(--red);
}

#contact h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 6.4rem;
  margin-top: 5rem;
}

#contact h2 {
  font-size: 2.2rem;
  color: var(--grey);
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

#contact p {
  margin-top: 0.8rem;
  font-size: 1.8rem;
}

#contact .contact-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5rem;
  gap: 2.5rem;
}

#contact .contact-btns a {
  color: var(--white);
  background-color: var(--red);
  border: 1px solid var(--red);
  border-radius: 5px;
  padding: 1.5rem 2.5rem 1rem 2.5rem;
  font-size: 1.8rem;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
}

#contact .contact-btns a:hover {
  background-color: var(--darkgrey);
  border: 1px solid var(--darkgrey);
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
            box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
            box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.contact-footer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  margin-top: 3rem;
  text-align: center;
  gap: 1rem;
  display: none;
}

@media screen and (max-width: 770px) {
  #contact {
    padding: 5rem;
  }
  #contact .about {
    margin: 0;
  }
  #contact h1 {
    margin-top: 3rem;
  }
  #contact .contact-btns {
    margin-top: 5rem;
  }
  #contact .contact-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  #contact .about img {
    width: 15rem;
  }
  #contact .circle {
    height: 15.5rem;
    width: 15.5rem;
  }
}
