:root {
  --black: #000;
  --darkgrey: #1a1a1a;
  --white: #fff;
  --offwhite: #f2f2f2;
  --lightgrey: #d3d3d3;
  --grey: #808080;
  --slategrey: #8d8d8d;
  --red: #ff0000;
  --darkred: #e70000;
  --green: #008000;
}
