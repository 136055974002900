#projects {
  padding: 10rem;
  display: flex;
  min-height: 100vh;

  background-color: var(--offwhite);
  color: var(--black);
}

.projects-desc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 5rem;

  h1 {
    font-family: 'Calibre-SB', sans-serif;
    font-size: 6.4rem;
  }
  p {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}

.projects-title-desc {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  gap: 1rem;
  span {
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    text-decoration-color: transparent;
    transition: all 0.3s ease;
  }
  .gh {
    color: var(--green);
  }
  .fsa {
    color: var(--red);
  }
  span:hover {
    text-underline-offset: 0.4rem;
    text-decoration-thickness: 1px;
    text-decoration-color: inherit;
  }
  .skills {
    display: grid;
    grid-template-columns: repeat(2, minmax(14rem, 20rem));
    margin-top: 2rem;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 1rem;
      padding-left: 2rem;
      font-size: 1.6rem;
    }
    li::before {
      content: '»';
      position: absolute;
      font-size: 1.6rem;
      line-height: 1.2rem;
      left: 0px;
      top: 3px;
      color: var(--red);
    }
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 970px) {
  #projects {
    height: auto;
    .projects-desc {
      display: block;
      .skills {
        margin: 2rem auto 0;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  #projects {
    height: auto;
    padding: 10rem 5rem;
  }
}
