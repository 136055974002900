.illustrations {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  -webkit-box-flex: 1;
      -ms-flex: 1 1 60%;
          flex: 1 1 60%;
}

.illustrations img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
