#contact {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem;
  text-align: center;
  min-height: 100vh;

  background-color: var(--offwhite);
  color: var(--black);

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10%;

    img {
      width: 15rem;
      border-radius: 100%;
      position: relative;
      transition: all 0.3s ease;
      animation: pulse 2s infinite;
    }
  }

  span {
    color: var(--red);
  }

  h1 {
    font-family: 'Calibre-SB', sans-serif;
    font-size: 6.4rem;
    margin-top: 5rem;
  }

  h2 {
    font-size: 2.2rem;
    color: var(--grey);
    letter-spacing: 1px;
    margin-bottom: 2rem;
  }

  p {
    margin-top: 0.8rem;
    font-size: 1.8rem;
  }

  .contact-btns {
    display: flex;
    margin-top: 5rem;
    gap: 2.5rem;
    a {
      color: var(--white);
      background-color: var(--red);
      border: 1px solid var(--red);
      border-radius: 5px;
      padding: 1.5rem 2.5rem 1rem 2.5rem;
      font-size: 1.8rem;
      line-height: 1;
      text-decoration: none;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        background-color: var(--darkgrey);
        border: 1px solid var(--darkgrey);
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

// =============================== FOOTER

.contact-footer {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 3rem;
  text-align: center;
  gap: 1rem;
  display: none;
}

// =============================== MEDIA QUERY

@media screen and (max-width: 770px) {
  #contact {
    .about {
      margin: 0;
    }
    padding: 5rem;
    h1 {
      margin-top: 3rem;
    }
    .contact-btns {
      margin-top: 5rem;
    }
    .contact-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 2rem 0 2rem;
    }
    .about img {
      width: 15rem;
    }
    .circle {
      height: 15.5rem;
      width: 15.5rem;
    }
  }
}
