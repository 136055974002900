.design-modal,
.design-modal-invisible {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  transition: opacity 0.5s;

  .modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 2%;
    padding: 2rem 1rem 4rem 2rem;
    height: 85vh;
    width: 60%;
    background-color: var(--darkgrey);
    color: var(--white);
    border-radius: 10px;
    z-index: 5;
  }

  .design-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1rem;
    gap: 1.5rem;
    overflow: auto;
    h1 {
      font-family: 'Calibre-SB', sans-serif;
      font-size: 3.6rem;
      margin-top: 1rem;
    }
    p {
      line-height: 2.5rem;
      font-size: 1.6rem;
      margin: 0 15% 1rem;
    }
    .modal-link {
      text-decoration: underline;
      text-underline-offset: 0.4rem;
      text-decoration-color: transparent;
      transition: all 0.3s ease;
    }
    .modal-link:hover {
      text-underline-offset: 0.4rem;
      text-decoration-thickness: 1px;
      text-decoration-color: inherit;
    }

    img {
      width: 100%;
      margin-top: 0rem;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-radius: 20px;
      background-color: var(--grey);
    }
  }
}

// ================== HIDE/SHOW MODAL

.design-modal-invisible {
  opacity: 0;
  pointer-events: none;
}

// ================== PTP MODAL

.ptp-imgs {
  .ptp-gifs {
    display: flex;
    gap: 1.5rem;
    height: auto;
    margin-bottom: 2rem;
    img {
      min-width: 48.3%;
    }
  }
}

// ================== SB MODAL

.sb-imgs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .sb-row-1,
  .sb-row-2,
  .sb-row-3,
  .sb-row-4 {
    display: flex;
    gap: 2rem;
    img {
      margin-top: 0;
      min-width: 46%;
    }
  }
  .sb-row-1 {
    margin-top: 1.5rem;
  }
  .sb-row-2 {
    img:nth-of-type(2) {
      object-fit: cover;
    }
  }
}

// ================== CLOSE BUTTON

.close-container {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  bottom: 2.5rem;
  right: 1rem;
}

.leftright,
.rightleft {
  height: 4px;
  width: 20px;
  position: absolute;
  margin-top: 24px;
  background-color: var(--red);
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  transform: rotate(-45deg);
}

// ================== LEFT / RIGHT CHEVRON

.modal-container {
  .next-proj,
  .prev-proj {
    position: absolute;
    bottom: 2.6rem;
    cursor: pointer;
  }

  .next-proj {
    right: 46%;
  }

  .prev-proj {
    right: 55%;
  }

  .chevron::before {
    color: var(--red);
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0;
    content: '';
    height: 0.75rem;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.75rem;
    border-radius: 3px;
  }

  .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  .chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 770px) {
  .design-modal .modal-container {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .design-modal .modal-container {
    width: 90%;
  }
}
