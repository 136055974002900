.project-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 5rem;
  min-width: 99%;

  .project-banner {
    display: flex;
    align-content: center;
    img {
      width: 100%;
      box-shadow: 0px 0px 10px rgba(71, 71, 71, 0.1);
      transition: all 0.3s ease;
    }
  }

  .project-info {
    display: flex;
    flex-direction: column;
  }

  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: var(--black);
      text-decoration: none;
      transition: all 0.2s ease;
    }
    p {
      font-size: 3.2rem;
      color: var(--black);
      :hover {
        color: var(--red);
      }
    }
    p:nth-of-type(2) {
      font-size: 1.6rem;
    }

    .project-links {
      display: flex;
      gap: 1rem;
    }

    svg {
      margin: 0;
    }
  }

  .project-body {
    display: flex;
  }

  .project-details-container {
    display: flex;
    flex-direction: column;
  }

  .project-details {
    .tech-stack {
      display: flex;
      flex-wrap: wrap;
      margin: 1.5rem 0 1rem 0;
      li {
        color: var(--grey);
        list-style: none;
        font-size: 1.6rem;
        margin-right: 1rem;
      }
    }
  }

  .project-desc {
    margin-top: 1rem;
    min-height: 11.3rem;
    p {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 970px) {
  .project-name {
    justify-content: flex-start;
    padding: 1rem 1rem 0 1rem;
  }
}
