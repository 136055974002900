.design-modal,
.design-modal-invisible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.design-modal .modal-container,
.design-modal-invisible .modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-top: 2%;
  padding: 2rem 1rem 4rem 2rem;
  height: 85vh;
  width: 60%;
  background-color: var(--darkgrey);
  color: var(--white);
  border-radius: 10px;
  z-index: 5;
}

.design-modal .design-info,
.design-modal-invisible .design-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 1rem;
  gap: 1.5rem;
  overflow: auto;
}

.design-modal .design-info h1,
.design-modal-invisible .design-info h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 3.6rem;
  margin-top: 1rem;
}

.design-modal .design-info p,
.design-modal-invisible .design-info p {
  line-height: 2.5rem;
  font-size: 1.6rem;
  margin: 0 15% 1rem;
}

.design-modal .design-info .modal-link,
.design-modal-invisible .design-info .modal-link {
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.design-modal .design-info .modal-link:hover,
.design-modal-invisible .design-info .modal-link:hover {
  text-underline-offset: 0.4rem;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-color: inherit;
          text-decoration-color: inherit;
}

.design-modal .design-info img,
.design-modal-invisible .design-info img {
  width: 100%;
  margin-top: 0rem;
}

.design-modal .design-info::-webkit-scrollbar-thumb,
.design-modal-invisible .design-info::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: var(--grey);
}

.design-modal-invisible {
  opacity: 0;
  pointer-events: none;
}

.ptp-imgs .ptp-gifs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
  height: auto;
  margin-bottom: 2rem;
}

.ptp-imgs .ptp-gifs img {
  min-width: 48.3%;
}

.sb-imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
}

.sb-imgs .sb-row-1,
.sb-imgs .sb-row-2,
.sb-imgs .sb-row-3,
.sb-imgs .sb-row-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}

.sb-imgs .sb-row-1 img,
.sb-imgs .sb-row-2 img,
.sb-imgs .sb-row-3 img,
.sb-imgs .sb-row-4 img {
  margin-top: 0;
  min-width: 46%;
}

.sb-imgs .sb-row-1 {
  margin-top: 1.5rem;
}

.sb-imgs .sb-row-2 img:nth-of-type(2) {
  -o-object-fit: cover;
     object-fit: cover;
}

.close-container {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  bottom: 2.5rem;
  right: 1rem;
}

.leftright,
.rightleft {
  height: 4px;
  width: 20px;
  position: absolute;
  margin-top: 24px;
  background-color: var(--red);
  border-radius: 2px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.rightleft {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.modal-container .next-proj,
.modal-container .prev-proj {
  position: absolute;
  bottom: 2.6rem;
  cursor: pointer;
}

.modal-container .next-proj {
  right: 46%;
}

.modal-container .prev-proj {
  right: 55%;
}

.modal-container .chevron::before {
  color: var(--red);
  border-style: solid;
  border-width: 0.5rem 0.5rem 0 0;
  content: '';
  height: 0.75rem;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 0.75rem;
  border-radius: 3px;
}

.modal-container .chevron.right:before {
  left: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.modal-container .chevron.left:before {
  left: 0.25em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

@media screen and (max-width: 770px) {
  .design-modal .modal-container {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .design-modal .modal-container {
    width: 90%;
  }
}
