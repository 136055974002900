#design {
  display: flex;
  color: var(--white);
  padding: 10rem;
  min-height: 100vh;
}

.designs-desc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  h1 {
    font-family: 'Calibre-SB', sans-serif;
    font-size: 6.4rem;
  }
  p {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}

.designs-title-desc {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  gap: 1rem;
  a {
    text-decoration: none;
  }
}

.adobe-icons {
  display: flex;
  justify-content: center;
  flex: 1 1 50%;
  gap: 5rem;
  transition: all 0.5s ease;
  .icon-row-1,
  .icon-row-2 {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    transition: all 0.5s ease;
  }
  img {
    max-width: 11rem;
    height: auto;
    border-radius: 30px;
    transition: all 0.3s ease;
  }
}

// ======================== MEDIA QUERY

@media screen and (max-width: 1004px) {
  .designs-desc {
    gap: 5rem;
  }
  .adobe-icons {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    .icon-row-1,
    .icon-row-2 {
      flex-direction: row;
      gap: 9rem;
    }
    img {
      max-width: 10rem;
    }
  }
}

@media screen and (max-width: 970px) {
  #design {
    padding: 0;
    min-height: 0;
    .designs-desc {
      display: block;
      gap: 0;
      .designs-title-desc {
        padding: 10rem;
      }
      .adobe-icons {
        padding: 0 5rem 10rem 5rem;
        .icon-row-1,
        .icon-row-2 {
          gap: 6rem;
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  #design .designs-desc .designs-title-desc {
    padding: 10rem 5rem 5rem 5rem;
  }
}

@media screen and (max-width: 500px) {
  .icon-row-1,
  .icon-row-2 {
    img {
      max-width: 6rem;
      border-radius: 20px;
    }
  }
}

// ======================== WORD / ICON GLOW

.ind {
  color: rgba(255, 48, 102, 1);
}
.ps {
  color: rgba(48, 168, 255, 1);
}
.ai {
  color: rgba(255, 154, 0, 1);
}
.xd {
  color: rgba(255, 97, 247, 1);
}
.pp {
  color: rgba(153, 153, 255, 1);
}
.ae {
  color: rgba(153, 153, 255, 1);
}
