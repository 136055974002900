.nav {
  position: fixed;
  top: 0;
  padding: 3rem;
  width: 100%;
  z-index: 1;
  background-color: var(--darkgrey);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.show {
  -webkit-transform: translateY(-105px);
          transform: translateY(-105px);
}

.nav-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 4.5rem;
}

.nav-content a {
  height: 100%;
  z-index: 3;
}

.nav-content .nav-logo {
  height: 100%;
  cursor: pointer;
}

.nav-content .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.6rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 2rem;
}

.nav-content .nav-links p {
  color: var(--white);
  letter-spacing: 0.1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-content .nav-links a {
  text-decoration: none;
}

.nav-content .nav-links a :hover {
  color: var(--red);
}

.ham-icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  height: 45px;
  cursor: pointer;
}

.ham-icon,
.ham-icon-open {
  width: 30px;
  height: 5px;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 4;
  display: none;
}

.ham-icon span,
.ham-icon-open span {
  position: absolute;
  height: 5px;
  width: 100%;
  background: var(--darkred);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 4;
}

.ham-icon span:nth-child(1) {
  top: -13px;
}

.ham-icon span:nth-child(2),
.ham-icon span:nth-child(3) {
  top: 0px;
}

.ham-icon span:nth-child(4) {
  top: 13px;
}

.ham-icon-open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

.ham-icon-open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.ham-icon-open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ham-icon-open span:nth-child(4) {
  top: 0px;
  width: 0%;
  left: 50%;
}

.overlay,
.overlay-visible {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  opacity: 0.6;
  background-color: var(--black);
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  visibility: hidden;
}

.overlay-visible {
  visibility: visible;
}

.ham-menu,
.ham-menu-visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 5rem 1rem;
  width: calc(min(75vw, 400px));
  min-height: 100vh;
  outline: 0px;
  color: var(--white);
  background-color: var(--darkgrey);
  z-index: 3;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
  visibility: hidden;
}

.ham-menu .ham-links,
.ham-menu-visible .ham-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  text-align: center;
  font-size: 2.2rem;
  gap: 4rem;
}

.ham-menu .ham-links hr,
.ham-menu-visible .ham-links hr {
  margin: auto;
  width: 10%;
  border: none;
  background-color: var(--grey);
  height: 1px;
}

.ham-menu .ham-links p,
.ham-menu-visible .ham-links p {
  color: var(--white);
  letter-spacing: 0.1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ham-menu .ham-links a,
.ham-menu-visible .ham-links a {
  text-decoration: none;
}

.ham-menu .ham-links a :hover,
.ham-menu-visible .ham-links a :hover {
  color: var(--red);
}

.ham-menu .ham-menu-social,
.ham-menu-visible .ham-menu-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}

.ham-menu-visible {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
}

@media screen and (max-width: 770px) {
  .nav .nav-links a {
    display: none;
  }
  .ham-icon,
  .ham-icon-open {
    float: right;
    display: block;
  }
}

@media screen and (min-width: 770px) {
  .ham-icon-wrapper,
  .ham-menu-visible,
  .overlay-visible {
    display: none;
  }
}

@media screen and (max-device-width: 500px) {
  .nav {
    padding: 1.5rem 2rem;
  }
}
