.hero {
  color: var(--white);
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10rem;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin: 1rem;
  gap: 9rem;
}

.container h2:first-of-type {
  font-size: 3.6rem;
  color: var(--red);
  letter-spacing: 0.1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.container h2:nth-of-type(2) {
  font-size: 2.2rem;
  color: var(--grey);
  letter-spacing: 0.4rem;
  margin-top: 2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.container .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container img {
  width: 15rem;
  -webkit-animation: logo-spin infinite 20s linear;
          animation: logo-spin infinite 20s linear;
}

@-webkit-keyframes logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.type-box {
  height: 8.5rem;
  width: 41rem;
}

.typed {
  height: 100%;
  overflow: hidden;
  border-right: 0.6em solid var(--grey);
  white-space: nowrap;
  -webkit-animation: typing 6s steps(18, end) forwards infinite, blink 1.2s infinite;
          animation: typing 6s steps(18, end) forwards infinite, blink 1.2s infinite;
}

.typed h1 {
  font-family: 'Calibre-SB', sans-serif;
  font-size: 9.6rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@-webkit-keyframes typing {
  0% {
    width: 0;
  }
  20% {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 0%;
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  20% {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  75% {
    width: 0%;
  }
  100% {
    width: 0%;
  }
}

@-webkit-keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: var(--grey);
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: var(--grey);
  }
}

.hero .chevron-container {
  bottom: 10rem;
  right: 10rem;
  position: absolute;
}

.hero .chevron-container .chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  -webkit-transform: scale(0.3);
          transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
          animation: move-chevron 3s ease-out infinite;
}

.hero .chevron-container .chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
          animation: move-chevron 3s ease-out 1s infinite;
}

.hero .chevron-container .chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
          animation: move-chevron 3s ease-out 2s infinite;
}

.hero .chevron-container .chevron:before,
.hero .chevron-container .chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: var(--red);
}

.hero .chevron-container .chevron:before {
  left: 0;
  -webkit-transform: skewY(30deg);
          transform: skewY(30deg);
}

.hero .chevron-container .chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skewY(-30deg);
          transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
            transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
            transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
            transform: translateY(4.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
            transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
            transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
            transform: translateY(4.8rem) scale(0.5);
  }
}

@media screen and (max-width: 500px) {
  .hero {
    padding: 10rem 5rem;
  }
  .hero .type-box {
    width: 30rem;
    height: 6.5rem;
  }
  .hero .type-box h1 {
    font-size: 7rem;
  }
  .hero h2:first-of-type {
    font-size: 3rem;
  }
  .hero h2:nth-of-type(2) {
    font-size: 2rem;
  }
}

@media screen and (max-width: 770px) {
  .hero .chevron-container {
    right: 50%;
  }
}

@media screen and (max-device-width: 500px) {
  .hero {
    padding-top: 5rem;
    text-align: center;
  }
  .hero .chevron-container {
    bottom: 15rem;
  }
  .hero .type-box,
  .hero h1,
  .hero h2:first-of-type,
  .hero h2:nth-of-type(2) {
    width: 32.5rem;
    letter-spacing: 2px;
  }
}
