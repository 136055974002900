.design-thumbnails {
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 33.33%;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .thumbnail-img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .thumbnail-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    text-align: center;
    color: var(--white);
    background: rgba(39, 39, 39, 0.5);
    width: 100%;
    height: 100%;
    padding: 2rem;
    transition: opacity 0.2s, visibility 0.2s;
    p {
      font-family: 'Calibre-SB', sans-serif;
      word-wrap: break-word;
      font-size: 3.6rem;
      line-height: 4rem;
      opacity: 0;
      transition: 0.2s;
    }
  }

  .thumbnail:hover + .thumbnail-hover,
  .thumbnail-hover:hover {
    opacity: 1;
    cursor: pointer;
    p {
      opacity: 0.8;
    }
  }
}

// ================== MEDIA QUERY

@media screen and (max-width: 900px) {
  .design-thumbnails {
    ul li {
      width: 50%;
    }
  }
}
