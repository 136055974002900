footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  font-size: 1.2rem;
  bottom: 0;
  left: 3.5rem;
  text-align: center;
  margin: 3.5rem 0;
}

footer hr {
  width: 1px;
  height: 9rem;
  border: none;
  background-color: var(--red);
}

footer a {
  padding: 0.5rem;
}

svg {
  width: 20px;
  height: 20px;
  fill: transparent;
}

@media screen and (max-width: 770px) {
  footer {
    opacity: 0;
  }
}
